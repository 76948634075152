<template>
  <section class="body">
    <section class="banner">
      <div class="summary">
        <h3>汽车安全“黑匣子”<strong>EDR</strong></h3>
        <ul class="tags">
          <li>断电存储</li>
          <li>数据保存</li>
          <li>隔离保护</li>
          <li>数据安全</li>
          <li>数据采集</li>
        </ul>
      </div>
      <img class="img" src="../../assets/vbd/edr/banner.png" width="1000" height="940" />
    </section>

    <div class="layout">
      <div class="content">
        <div class="cover">
          <img class="img" src="../../assets/vbd/edr/cover.png" width="400" height="290" />
          <p>EDR</p>
        </div>
        <div class="col main">
          <h3>汽车安全“黑匣子” EDR</h3>
          <div class="inset">
            <img class="img" src="../../assets/vbd/edr/inset.png" width="200" height="160" />
            <p>CDR</p>
          </div>
          <div class="item">
            <h4>断电存储</h4>
            <p>外部电源丢失后可继续存储数据</p>
          </div>
        </div>
        <div class="col">
          <div class="item">
            <h4>数据保存</h4>
            <p>锁定事件永久保存，锁定存储事件数量可扩展</p>
          </div>
          <div class="item">
            <h4>数据安全</h4>
            <p>支持数据加密存储和数据签名，保证存储和传输数据安全</p>
          </div>
        </div>
        <div class="col">
          <div class="item">
            <h4>隔离保护</h4>
            <p>设备具备电源与通信隔离，设备故障不影响车辆运行</p>
          </div>
          <div class="item">
            <h4>数据采集</h4>
            <p>满足GB39732中数据记录B类最高要求</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "EDR",
};
</script>

<style lang="scss" scoped>
.layout {
  padding-top: 180px;
  padding-bottom: 180px;
  max-width: 1440px;
}

.banner {
  display: flex;
  padding-top: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 850px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background-color: #f15a24;

  .summary {
    position: relative;
    font-size: 24px;
    line-height: 28px;
    transform: translateX(-50%);

    h3 {
      font-size: 60px;
      color: #fff;
      font-weight: 300;
      line-height: 64px;

      strong {
        display: block;
        padding-top: 16px;
        font-size: 160px;
        line-height: 188px;
        font-weight: 900;
        font-family: Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
      }
    }

    .tags {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 540px;

      li {
        margin: 10px;
        padding: 0 18px;
        font-size: 22px;
        line-height: 38px;
        border: 2px solid #fff;
        border-radius: 6px;
      }
    }
  }

  .img {
    position: absolute;
    z-index: 1;
    transform: translate(350px, 225px);
  }
}

.content {
  display: flex;
  font-size: 24px;
  line-height: 36px;

  .cover {
    position: relative;
    width: 400px;
    height: 290px;

    p {
      position: absolute;
      left: 50px;
      bottom: 12px;
    }
  }

  .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .col {
    display: flex;
    margin-left: 50px;
    padding-top: 100px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    &.main {
      padding-top: 40px;
      position: relative;

      h3 {
        position: absolute;
        left: calc(100% + 50px);
        top: 12px;
        white-space: nowrap;
        font-size: 36px;
        line-height: 46px;
      }
    }
  }

  .inset {
    margin: 0 0 32px;
    width: 200px;
    transform: translateX(-24px);

    p {
      padding-left: 24px;
    }
  }

  .item {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;

    h4 {
      margin-bottom: 4px;
      font-size: 26px;
      line-height: 36px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .layout {
    padding-top: 80px;
    padding-bottom: 80px;

    h3 {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .banner {
    display: flex;
    height: auto;
    min-height: 100vh;
    padding: 120px 0 60px;
    flex-direction: column-reverse;

    .summary {
      margin-top: -100px;
      transform: none;
      z-index: 5;

      h3 {
        font-size: 30px;
        line-height: 32px;

        strong {
          padding-top: 8px;
          font-size: 72px;
          line-height: 86px;
        }
      }

      .tags {
        padding: 20px 0 0;
        max-width: 240px;

        li {
          margin: 3px;
          padding: 0 6px;
          font-size: 12px;
          line-height: 18px;
          border: 1px solid #fff;
          border-radius: 4px;
        }
      }
    }

    .img {
      position: static;
      transform: none;
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: block;
    font-size: 16px;
    line-height: 24px;

    .cover {
      margin: 0 auto;
      width: 280px;
      height: 204px;

      p {
        position: absolute;
        left: 20px;
        bottom: 6px;
      }
    }

    .col {
      margin-left: 0px;
      padding: 0 10px;
      flex-direction: row;
      flex-wrap: wrap;

      &.main {
        align-items: flex-end;

        h3 {
          margin-bottom: 16px;
          position: static;
          font-size: 22px;
          line-height: 30px;
          width: 100%;
        }
      }
    }

    .inset {
      flex: 1;
      width: auto;
      transform: translateX(-16px);

      & + .item {
        margin-left: 24px;
      }
    }

    .item {
      flex: 1;
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 20px;

      & + .item {
        margin-left: 24px;
      }

      h4 {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
</style>